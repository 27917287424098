<template>
  <div
    :class="{
      'flix-overlay flix-flex flix-flex-center':
        $store.state.form.newElement === index
    }"
  >
    <ul
      v-if="$store.state.form.newElement === index"
      class="flix-flex flix-flex-list flix-gap-5"
      style="margin-top: 0px;"
    >
      <li style="text-align: right; float: right">
        <a
          href="#"
          @click.prevent="$store.commit('form/setNewElement', false)"
          class="flix-html-a"
          ><icon id="menu-close"
        /></a>
      </li>
      <li v-for="element in elements" :key="element.title">
        <a
          href="#"
          class="flix-flex flix-gap-5 flix-html-a"
          @click.prevent="element.callback"
          v-if="checkCondition(element)"
        >
          <icon :id="element.icon" class="flix-grafic" />
          <span class="flix-flex flix-flex-list flix-html-a">
            <b v-html="element.title"></b>
            {{ element.description }}
          </span>
        </a>
      </li>
    </ul>
    <a
      href="#"
      v-else
      @click.prevent="$store.commit('form/setNewElement', index)"
      class="flix-btn flix-btn-xs"
      ><icon id="plus"
    /></a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: [Number, String]
  },
  data() {
    return {
      elements: [
        {
          icon: "user-group",
          title: this.$t("form.group.title"),
          description: this.$t("form.group.info"),
          callback: function() {
            this.addGroup();
          }.bind(this),
          condition: function() {
            return this.checkDoublicate("group");
          }.bind(this)
        },
        {
          icon: "header",
          title: this.$t("form.heading.title"),
          description: this.$t("form.heading.info"),
          callback: function() {
            this.addHeader();
          }.bind(this)
        },
        {
          icon: "square-info",
          title: this.$t("form.paragraph.title"),
          description: this.$t("form.paragraph.info"),
          callback: function() {
            this.addParagraph();
          }.bind(this)
        },
        {
          icon: "link",
          title: this.$t("form.link.title"),
          description: this.$t("form.link.info"),
          callback: function() {
            this.addLink();
          }.bind(this)
        },
        {
          icon: "call",
          title: this.$t("form.phone.title"),
          description: this.$t("form.phone.info"),
          callback: function() {
            this.addTel();
          }.bind(this),
          condition: function() {
            return this.checkDoublicate("tel");
          }.bind(this)
        },
        {
          icon: "text-underline",
          title: this.$t("form.text.title"),
          description: this.$t("form.text.info"),
          callback: function() {
            this.addText();
          }.bind(this)
        },
        {
          icon: "text-resize",
          title: this.$t("form.textarea.title"),
          description: this.$t("form.textarea.info"),
          callback: function() {
            this.addTextarea();
          }.bind(this)
        },
        {
          icon: "circle-selected",
          title: this.$t("form.radio.title"),
          description: this.$t("form.radio.info"),
          callback: function() {
            this.addRadio();
          }.bind(this)
        },
        {
          icon: "square-check",
          title: this.$t("form.checkbox.title"),
          description: this.$t("form.checkbox.info"),
          callback: function() {
            this.addCheckbox();
          }.bind(this)
        },
        {
          icon: "legal-section-sign",
          title: this.$t("form.terms.title"),
          description: this.$t("form.terms.info"),
          callback: function() {
            this.addAGB();
          }.bind(this),
          condition: function() {
            return this.checkDoublicate("agb");
          }.bind(this)
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    checkCondition(element) {
      if (typeof element.condition === "function") {
        return element.condition();
      }
      return true;
    },
    getCount() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .form;

      var c = {};
      data.forEach(function(d) {
        var type = d.type;
        if (type === "text") {
          type = d.subtype;
        }
        if (type === "paragraph") {
          type = d.subtype;
        }
        if (typeof c[type] === "undefined") {
          c[type] = 0;
        }
        c[type] = c[type] + 1;
      });
      return c;
    },
    checkElement(type, subtype) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .form;
      var exists = false;
      data.forEach(function(d) {
        if (d.type === type && d.subtype === subtype) {
          exists = true;
        }
      });
      return exists;
    },
    addGroup() {
      if (this.checkElement("group", "select")) {
        return false;
      }
      var copy = {
        type: "group",
        subtype: "select",
        label: "Wie viele Personen nehmen insgesamt am Termin teil?",
        columnName: "12",
        description: "Diese Anzahl an Terminen werden für Sie reserviert",
        required: false,
        values: "auto"
      };
      this.setSave(copy);
    },
    addLink() {
      var copy = {
        type: "paragraph",
        subtype: "a",
        label: "Link",
        columnName: "12",
        description: "<a href=''>Link</a>",
        required: false,
        value: ""
      };
      this.setSave(copy);
    },
    addCheckbox() {
      var copy = {
        type: "checkbox",
        subtype: "checkbox",
        label: "Mehrfachauswahl",
        columnName: "12",
        description: "",
        required: false,
        value: "",
        values: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
          { label: "Option 3", value: "Option 3" }
        ]
      };
      this.setSave(copy);
    },
    addRadio() {
      var copy = {
        type: "radio",
        subtype: "radio",
        label: "Einzelauswahl",
        columnName: "12",
        description: "",
        required: false,
        value: "",
        values: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
          { label: "Option 3", value: "Option 3" }
        ]
      };
      this.setSave(copy);
    },
    addAGB() {
      var copy = {
        type: "agb",
        subtype: "agb",
        label: "Ich stimme den AGBs zu",
        columnName: "12",
        description: "",
        required: true,
        value: "",
        values: ["yes", "no"]
      };
      this.setSave(copy);
    },
    addTel() {
      if (this.checkElement("text", "tel")) {
        return false;
      }
      var copy = {
        type: "text",
        subtype: "tel",
        label: "Handynummer",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addText() {
      var copy = {
        type: "text",
        subtype: "text",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addTextarea() {
      var copy = {
        type: "textarea",
        subtype: "textarea",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addParagraph() {
      var copy = {
        type: "paragraph",
        subtype: "p",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    addHeader() {
      var copy = {
        type: "header",
        subtype: "h2",
        label: "",
        columnName: "12",
        description: "",
        required: false
      };
      this.setSave(copy);
    },
    setSave(copy) {
      copy.id = new Date().getTime();
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      var pos = this.index;

      if (pos === "top") {
        pos = 0;
        data.form.splice(pos, 0, copy);
      }
      if (pos === "bottom") {
        data.form.push(copy);
        pos = data.form.length - 1;
      } else {
        data.form.splice(pos, 0, copy);
      }

      this.$store.commit("business/prefetch", data);
      this.$store.commit("form/setEdit", pos);
      this.$store.commit("form/setNewElement", false);
    },
    checkDoublicate(part) {
      var form = this.$store.state.business.unsaved.form;
      var r = true;
      form.forEach(function(f) {
        if (f.subtype === part || f.type === part) {
          r = false;
        }
      });
      return r;
    }
  }
};
</script>
<style lang="sass" scoped>
.flix-overlay
  position: absolute
  // border: 1px solid black
  padding: 5px 10px
  background-color: white
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5)
  ul
    li
      display: block
      margin-bottom: 2px
      a
        color: black
      .flix-grafic
        border: 1px solid black
        padding: 10px
      span
        font-size: 10pt
        b
          display: block
          margin-bottom: 0
</style>
